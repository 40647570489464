import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import "../scss/main.scss"
import bannerImage from "../../static/images/banner/magazynowanie-1.jpg"
import ServicePage from "../layouts/ServicesPage"

import LogisticsContent from "../content/Logistics"

export default function MeltingPage() {
  const intl = useIntl()
  return <ServicePage bannerImage={bannerImage}>
    <LogisticsContent lang={intl.locale} />
  </ServicePage>
}
