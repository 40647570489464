import React from "react"

const LogisticsEN = () => <>
  <h2>Logistics</h2>
  <p className="bigger-text text-center">We have our own transport fleet, which thanks to constant monitoring of the
    position and
    parameters of our vehicles' work, allows for fast and safe delivery of cargo in Europe.
    We offer a wide range of logistics services:</p>
  <ul className="list-center bigger-text">
    <li>Road transport FTL (Full Truck Load ),</li>
    <li>Oversized loads transport,</li>
    <li>Special freights transport,</li>
    <li>Storage</li>
  </ul>
</>

export default LogisticsEN