import React from "react"

const LogisticsDE = () => <>
  <h2>Logistik</h2>
  <ul className="list-center bigger-text">
    <li>Eigene Transportflotte,</li>
    <li>FTL - Transport,</li>
    <li>überdimensionale Sondertransporte,</li>
    <li>ständige Streckenüberwachung,</li>
    <li>Lagerung</li>
  </ul>
</>

export default LogisticsDE