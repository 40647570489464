import React from "react"

const LogisticsPL = () => <>
  <h2>Logistyka / Magazynowanie</h2>
  <p className="bigger-text text-center">Wynajem powierzchni magazynowej</p>
  <p>Posiadamy magazyn o powierzchni 3000 m² , monitoring całodobowy wewnątrz i na zewnątrz obiektu , dok
    załadunkowy, plac magazynowy o powierzchni 10000 m2 , służymy szybką kompleksową obsługą dla
    zabezpieczenia Państwa potrzeby w zakresie magazynowania i przeładunków. Jesteśmy solidnym
    partnerem, dzięki któremu znacząco obniżycie koszty prowadzenia biznesu.</p>
  <p>Rozumiemy, jak ważna jest łatwo dostępna i specjalistyczna przestrzeń magazynowa. Dla naszych klientów
    przygotowaliśmy profesjonalną przestrzeń przeznaczoną pod magazynowanie ładunków paletowych,
    ładunków gabarytowych w zależności od potrzeb klienta.</p>
  <p>Nasz zespół gwarantuje szybką kompletację i przeładunek zgodnie z Państwa wytycznymi.</p>
  <p>Zachęcamy do kontaktu. Odpowiemy na wszystkie Państwa pytania!</p>
</>

export default LogisticsPL
