import React from "react"
import LogisticsDE from "./logistics.de"
import LogisticsEN from "./logistics.en"
import LogisticsPL from "./logistics.pl"

const LogisticsContent = ({ lang }) => {
  if (lang === "de") {
    return <LogisticsDE />
  } else if (lang === "en") {
    return <LogisticsEN />
  }
  return <LogisticsPL />
}

export default LogisticsContent